const Dialog = {
	MuiDialog: {
		styleOverrides: {
			root: {
				'&.SizeGuide-root .MuiDialog-paperWidthSm': {
					minHeight: 'auto',
				},
			},
		},
	},
}
export default Dialog
